<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20">
    <path
      fill="#E5472D"
      fill-rule="evenodd"
      d="M13.22 1.715l-1.863 2-1.863-2a5.387 5.387 0 0 0-7.882 0 5.947 5.947 0 0 0 0 8.142L11.5 20l9.888-10.143a5.947 5.947 0 0 0 0-8.142 5.721 5.721 0 0 0-8.074-.095l-.094.095z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
